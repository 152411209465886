import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import getTest from '../api/getTest'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    data: {
      color: '#fff',
    },
  })
)

type Props = {}

const Detail: React.FC = (_: Props) => {
  const classes = useStyles()
  const [str, setStr] = useState('')
  useEffect(() => {
    if (str === '') {
      const fetchData = async () => {
        const result = await getTest()
        setStr(JSON.stringify(result))
      }
      fetchData()
    }
  })

  return (
    <>
      <h1>Hello, test</h1>
      <p className={classes.data}>{str}</p>
    </>
  )
}
export default Detail
