import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import milkywayImage from '../../statics/img/milkyway.jpg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseArea: {
      width: '100%',
      margin: 0,
      padding: 0,
      backgroundColor: '#eef',
      color: '#000',
      textAlign: 'center',
    },
    responsiveImage: {
      width: '100%',
      margin: 0,
      padding: 0,
    },
    responsiveText: {
      width: '100%',
      margin: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
)

const TopText = () => {
  const classes = useStyles()
  return (
    <div className={classes.baseArea}>
      <img
        className={classes.responsiveImage}
        src={milkywayImage}
        alt='growth'
      />
      <div className={classes.responsiveText}>
        <Typography variant='h4' display='block'>
          その戦略を形にする
        </Typography>
        <div className={classes.responsiveText}>
          <Typography variant='body1' display='block'>
            天星システムでは
            <br />
            クラウドを用いたWebサービスの構築を
            <br />
            要件定義のヒアリングから運用まで
            <br />
            ワンストップで提供する事ができます
            <br />
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default TopText
