interface IHttpResponse<T> extends Response {
  parsedBody?: T;
}

export const http = <T>(request: RequestInfo): Promise<IHttpResponse<T>> => {
  let response: IHttpResponse<T>;
  return new Promise(resolve => {
    fetch(request)
      .then(res => {
        response = res;
        return res.json();
      })
      .then(body => {
        response.parsedBody = body;
        resolve(response);
      });
  });
};

const fetchJsonAsObject = <T>(
  url: RequestInfo,
  init?: RequestInit
): Promise<T> => {
  return new Promise(resolve => {
    fetch(url, init)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('api error');
      })
      .then(body => {
        resolve(body as T);
      });
  });
};

export const getApi = async <T>(
  url: RequestInfo,
  init?: RequestInit
): Promise<T> => {
  const realInit =
    init === undefined ? { method: 'GET' } : { ...init, method: 'GET' };
  return await fetchJsonAsObject(url, realInit);
};

export const postApi = async <T>(
  url: RequestInfo,
  body: Object,
  init?: RequestInit
): Promise<T> => {
  const realInit =
    init === undefined
      ? { method: 'POST', body: JSON.stringify(body) }
      : { ...init, method: 'POST', body: JSON.stringify(body) };
  return await fetchJsonAsObject<T>(url, realInit);
};
