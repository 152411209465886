import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Header from './components/Header';
import Home from './components/Home';
import Detail from './components/Detail';
import ScrollTest from './components/ScrollTest';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topIndent: {
      marginTop: theme.spacing(8)
    }
  })
);

const App: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <BrowserRouter>
        <Header />
        <div className={classes.topIndent}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/detail/" component={Detail} />
            <Route path="/scroll-test/" component={ScrollTest} />
            <Redirect to="/" />
          </Switch>
        </div>
      </BrowserRouter>
    </>
  );
};

export default App;
