import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import logo from '../statics/logo.svg';
import { Typography } from '@material-ui/core';

const ScrollTest = () => {
  return (
    <div>
      <Link to="hoge" smooth={true}>
        eee
      </Link>
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <img src={logo} alt="logo" />
      <section id="hoge">
        <p>Index</p>
      </section>
      <div onClick={scroll.scrollToTop}>
        <Typography>Topへ</Typography>
      </div>
    </div>
  );
};

export default ScrollTest;
