import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseArea: {
      width: '100%',
      margin: 0,
      padding: 0,
      backgroundColor: '#333',
      color: '#fff',
      textAlign: 'center',
      borderTop: '3px #3a6 solid',
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start',
    },
    leftText: {
      width: '50%',
      margin: 0,
      textAlign: 'left',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
    },
    miniTextBlock: {
      margin: 0,
      textAlign: 'left',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  })
)

const BottomText = () => {
  const classes = useStyles()
  return (
    <div className={classes.baseArea}>
      <div className={classes.leftText}>
        <Typography variant='h6' display='block'>
          天星システム
        </Typography>
        <div className={classes.miniTextBlock}>
          <Typography variant='body1' display='block'>
            Site
          </Typography>
          <Typography variant='body2' display='block'>
            https://www.heavensstar.net/
          </Typography>
        </div>
        <div className={classes.miniTextBlock}>
          <Typography variant='body1' display='block'>
            Email
          </Typography>
          <Typography variant='body2' display='block'>
            biz@heavensstar.net
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default BottomText
