import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Typography, List, ListItem, ListItemText } from '@material-ui/core'
import milkywayImage from '../../statics/img/money.jpg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseArea: {
      width: '100%',
      margin: 0,
      padding: 0,
      backgroundColor: '#ffe',
      color: '#300',
      textAlign: 'center',
    },
    responsiveImage: {
      width: '100%',
      margin: 0,
      padding: 0,
    },
    responsiveText: {
      width: '100%',
      margin: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    textBlock: {
      width: '80%',
      textAlign: 'left',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(8),
    },
  })
)

const TopText = () => {
  const classes = useStyles()
  return (
    <div className={classes.baseArea}>
      <img
        className={classes.responsiveImage}
        src={milkywayImage}
        alt='Starry Sky'
      />
      <div className={classes.responsiveText}>
        <div className={classes.textBlock}>
          <Typography variant='h5'>得意分野</Typography>
          <Typography variant='body1'>
            <div>
              <List>
                <ListItem>
                  <ListItemText>
                    幅広い業務分野の経験・知識に基づく要件定義・機能設計
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    論理的思考から抽象化を行うデータベース設計・効率的なデータ分析
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    アジャイルプラクティスを活かした
                    <br />
                    小規模プロジェクト現場におけるリードエンジニアリング・PMO業務
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    AWSやGCPといったクラウドを用いたWebサービスのインフラ設計・構築
                  </ListItemText>
                </ListItem>
              </List>
            </div>
          </Typography>
        </div>
        <div className={classes.textBlock}>
          <Typography variant='h5'>開発言語</Typography>
          <Typography variant='body1'>
            <List>
              <ListItem>
                <ListItemText>
                  PHP,Pythonなどの軽量言語によるWebシステム・RestAPI開発
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  BigQueryやRDSから要望に基づくデータを効率的に抽出するためのSQL作成
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  React + TypeScript, Next.jsを用いたWebフロントの開発
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Unity + C#によるスマートフォン向けゲーム開発
                </ListItemText>
              </ListItem>
            </List>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default TopText
