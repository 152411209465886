import { getApi } from '../lib/api';

type WeatherCoordJson = {
  lon: number;
  lat: number;
};

type WeatherWeatherJson = {
  id: number;
  main: string;
  description: string;
  icon: string;
};

type WeatherMainJson = {
  temp: number;
  pressure: number;
  humidity: number;
  temp_min: number;
  temp_max: number;
};

type WeatherWindJson = { speed: number; deg: number };
type WeatherCloudJson = { all: number };
type WeatherSysJson = {
  type: number;
  id: number;
  message: number;
  country: String;
  sunrise: number;
  sunset: number;
};

type WeatherJson = {
  coord: WeatherCoordJson;
  weather: WeatherWeatherJson[];
  base: string;
  main: WeatherMainJson;
  visibility: number;
  wind: WeatherWindJson;
  clouds: WeatherCloudJson;
  dt: number;
  sys: WeatherSysJson;
  id: number;
  name: String;
  cod: number;
};

const getTest = async (): Promise<WeatherJson> => {
  return getApi<WeatherJson>('http://localhost:3000/json/a.json');
};

export default getTest;
