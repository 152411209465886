import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import TopText from './TopText'
import MiddleText from './MiddleText'
import BottomText from './BottomText'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    componentArea: {
      width: '100%',
      margin: 0,
      padding: 0,
    },
    componentAreaOver: {
      width: '1280px',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

const Home = () => {
  const classes = useStyles()
  const isOverWidth = useMediaQuery('(min-width:1280px)')
  return (
    <div
      className={
        isOverWidth ? classes.componentAreaOver : classes.componentArea
      }
    >
      <TopText />
      <MiddleText />
      <BottomText />
    </div>
  )
}

export default Home
