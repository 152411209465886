import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Typography, AppBar, Toolbar, Menu, MenuItem } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: '#002',
    },
    menuButton: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'block',
    },
    button: {
      margin: theme.spacing(1),
      color: '#fff',
    },
    menuLink: {
      color: '#222',
      textDecoration: 'none',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  })
)

export default function PrimarySearchAppBar() {
  const classes = useStyles()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      MenuListProps={{ onClick: handleMobileMenuClose }}
    >
      <MenuItem>
        <Link to='/' className={classes.menuLink}>
          Home
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to='/detail/' className={classes.menuLink}>
          Detail
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to='/scroll-test/' className={classes.menuLink}>
          ScrollTest
        </Link>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.grow}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title} variant='h6' noWrap>
            Heaven's Star Systems
          </Typography>
          <div className={classes.grow} />
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  )
}
